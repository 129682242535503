// 園の施設類型の一覧の取得

import api from './api'

const path = '/organization-facility-types'

export default () => {
  return api({
    url: path,
    auth: true,
  })
}
