// お知らせの削除

import api from '../api'

const path = '/admin/informations/{id}'

export default (informationId) => {
  const url = path.replace('{id}', informationId)
  return api({
    url,
    method: 'delete',
    auth: true,
  })
}
