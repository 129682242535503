// プッシュ通知関連

import api from '@/helpers/api'

// 通知済みの一覧の取得 (管理者用)
async function getListForAdmin() {
  const path = '/admin/push-notifications'
  return api({
    url: path,
    needsAuth: true,
  })
}

// お知らせに関する通知の実行 (管理者用)
async function pushForAdmin(informationId, title, body) {
  const path = '/admin/push-notifications'
  return api({
    method: 'post',
    url: path,
    body: {
      informationId,
      title,
      body,
    },
    needsAuth: true,
  })
}

export default {
  getListForAdmin,
  pushForAdmin,
}
