// ファイルアップロード用のURLの取得

import api from '../api'

const path = '/admin/upload-url'

export default (bucket, filename) => {
  const encodedFilename = encodeURIComponent(filename)
  const url = `${path}?bucket=${bucket}&filename=${encodedFilename}`
  return api({
    url,
    auth: true,
  })
}
