// お知らせ関連

import api from '@/helpers/api'

// お知らせの一覧の取得
async function getInformationList() {
  const path = '/informations'
  return api({
    url: path,
    needsAuth: true,
  })
}

// お知らせの詳細の取得
async function getInformation(informationId) {
  const path = '/informations/{id}'
  const url = path.replace('{id}', informationId)
  return api({
    url,
    needsAuth: true,
  })
}

// お知らせの作成 (管理者用)
async function create({
  title,
  date,
  isOpened,
  body,
  attachments,
  userGroupId,
  facilityTypeId = undefined,
}) {
  const path = '/admin/informations'
  return api({
    method: 'post',
    url: path,
    body: {
      title,
      date,
      userGroupId,
      isOpened,
      body,
      attachments:
        attachments && attachments.length > 0 ? attachments.map((item) => item.timestamp) : null,
      facilityTypeId,
    },
    needsAuth: true,
  })
}

// 管理者向けのお知らせの取得 (管理者用)
async function getForAdmin(informationId) {
  const path = '/admin/informations/{id}'
  const url = path.replace('{id}', informationId)
  return api({
    url,
    needsAuth: true,
  })
}

// 管理者向けのすべてのお知らせの取得 (管理者用)
async function getInformationListForAdmin() {
  const path = '/admin/informations'
  return api({
    url: path,
    needsAuth: true,
  })
}

// お知らせの削除 (管理者用)
async function removeInformation(informationId) {
  const path = '/admin/informations/{id}'
  const url = path.replace('{id}', informationId)
  return api({
    url,
    method: 'delete',
    needsAuth: true,
  })
}

// お知らせの更新 (管理者用)
async function update(
  informationId,
  { title, date, userGroupId, isOpened, body, attachments, facilityTypeId = undefined }
) {
  const path = '/admin/informations/{id}'
  const url = path.replace('{id}', informationId)
  return api({
    method: 'put',
    url,
    body: {
      title,
      date,
      userGroupId,
      isOpened,
      body,
      attachments:
        attachments && attachments.length > 0 ? attachments.map((item) => item.timestamp) : null,
      facilityTypeId,
    },
    needsAuth: true,
  })
}

// お知らせの対象ユーザグループの取得(管理者用)
async function getTargetUserGroups() {
  const path = '/admin/informations/target-user-groups'
  return api({
    url: path,
    needsAuth: true,
  })
}

export default {
  getInformationList,
  getInformation,
  create,
  getForAdmin,
  getInformationListForAdmin,
  removeInformation,
  update,
  getTargetUserGroups,
}
