// お知らせの管理

<template lang="pug">
el-card.admin-informations

  header
    .go-back(style='margin-bottom: 0.5rem')
      el-button(type='text', icon='el-icon-back', @click='$router.go(-1)') 戻る

    el-button(
      type='primary',
      icon='el-icon-circle-plus',
      @click='createInformation'
    ) お知らせを作成

  el-table.information-list(:data='informations')

    el-table-column(
      label='状態'
    )
      template(slot-scope='scope')
        .inline-block.p-1.rounded-full.text-xs.font-bold.text-white(
          :class='[scope.row.isOpened ? "bg-teal-500" : "bg-gray-400"]'
        ) {{ scope.row.isOpened ? '公開中' : '非公開' }}

    el-table-column(
      label='対象者'
    )
      template(slot-scope='scope')
        span {{ scope.row.userGroupName.slice(0, 3) }}
        p(v-if='scope.row.organizationFacilityTypeName') ({{ scope.row.organizationFacilityTypeName }})


    el-table-column(
      label='表示日時'
    )
      template(slot-scope='scope')
        span {{ scope.row.date | jpDate }}

    el-table-column(
      label='タイトル',
      prop='title'
    )
      template(slot-scope='scope')
        span {{ scope.row.title }}
        fa-icon.ml-1.text-blue-500(
          v-if='scope.row.hasAttachments',
          icon='paperclip'
        ) 
    
    el-table-column(
      label='アプリ通知',
      align='center'
    )
      template(slot-scope='scope')
        //- 通知可能
        template(v-if='scope.row.isNotifiable')
          p(v-if='scope.row.appNotifiedAt') 通知済み
          p.text-blue-700.underline.cursor-pointer(
            @click='openPushNotificationDialog(scope.row)'
          ) {{ scope.row.appNotifiedAt ? '(再通知)' : '通知する' }}
        //- 通知非対応
        p(v-else) ---

    el-table-column(
      align='right'
    )
      template(slot-scope='scope')
        el-button(
          size='mini',
          type='success',
          plain,
          @click='modifyInformation(scope.row.id)'
        ) 編集
        el-button(
          size='mini',
          type='danger',
          plain,
          @click='removeInformation(scope.row.id)'
        ) 削除

  modify-information-dialog(
    :visible.sync='dialogVisible',
    :information-id='currentInformationId',
    @update-information='updateInformation'
  )
  push-notification-dialog(
    :visible.sync='pushNotificationDialogVisible',
    :information-id='pushNotificationDialogInformationId',
    @ok='init'
  )
</template>

<script>
import getInformations from '@/api/admin/get-informations'
import removeInformation from '@/api/admin/remove-information'

import ModifyInformationDialog from '@/dialogs/modify-information-dialog'
import PushNotificationDialog from '@/dialogs/push-notification-dialog.vue'

export default {
  name: 'AdminInformations',

  components: {
    ModifyInformationDialog,
    PushNotificationDialog,
  },

  data() {
    return {
      informations: [],
      dialogVisible: false,
      pushNotificationDialogVisible: false,
      pushNotificationDialogInformationId: undefined,
      currentInformationId: undefined,
    }
  },

  created() {
    this.init()
  },

  methods: {
    async init() {
      const response = await getInformations()
      if (!response.ok) return
      this.informations = response.payload.items
    },
    createInformation() {
      this.currentInformationId = null
      this.dialogVisible = true
    },
    modifyInformation(informationId) {
      this.currentInformationId = informationId
      this.dialogVisible = true
    },
    async removeInformation(informationId) {
      try {
        await this.$confirm('本当に削除してもよろしいですか？', '確認', {
          confirmButtonText: '削除',
          cancelButtonText: 'キャンセル',
        })
      } catch (e) {
        // キャンセルが押された
        return
      }
      const response = await removeInformation(informationId)
      if (!response.ok) {
        this.$alert('削除できませんでした。', 'エラー', {
          type: 'error',
        })
      } else {
        this.$message({
          message: '削除しました。',
          type: 'success',
        })
        await this.init()
      }
    },
    updateInformation() {
      this.$message({
        message: 'お知らせを保存しました。',
        type: 'success',
      })
      this.init()
    },
    openPushNotificationDialog(item) {
      this.pushNotificationDialogInformationId = item.id
      this.pushNotificationDialogVisible = true
    },
  },
}
</script>
