// プッシュ通知ダイアログ

<template lang="pug">
el-dialog(
  title='アプリへの通知',
  :close-on-click-modal='false',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)',
  @open='open',
  append-to-body
)
  p アプリ利用者へのプッシュ通知を行います。

  el-form(:model='form', :rules='rules', ref='form')
    el-form-item(label='タイトル', prop='title')
      el-input(v-model='form.title')
      p.mt-1.text-xs.text-green-700 255文字まで
    el-form-item(label='本文', prop='body')
      el-input(
        type='textarea',
        :rows='3',
        v-model='form.body'
      )
      p.mt-1.text-xs.text-green-700 255文字まで

  footer
    el-button(
      type='primary',
      @click='send'
    ) 通知を実行
    el-button(@click='close') キャンセル
</template>

<script>
import pushNotificationService from '@/services/push-notification'
import informationService from '@/services/information'

export default {
  name: 'PushNotificationDialog',

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    informationId: {
      type: Number,
    },
  },

  data() {
    return {
      form: {
        title: undefined,
        body: undefined,
      },
      rules: {
        title: [
          { required: true, message: 'タイトルを入力してください。' },
          { type: 'string', max: 255, message: '最大255文字までです。' },
        ],
        body: [
          { required: true, message: '本文を入力してください。' },
          { type: 'string', max: 255, message: '最大255文字までです。' },
        ],
      },
      information: undefined,
    }
  },

  methods: {
    async open() {
      // フォームをリセット
      if (this.$refs.form) this.$refs.form.resetFields()
      if (this.informationId) await this._initialize()
    },

    async _initialize() {
      try {
        const res = await informationService.getForAdmin(this.informationId)
        if (!res.ok) throw new Error('cannot get information.')
        this.form.title = '新しいお知らせがあります'
        this.form.body = `お知らせ「${res.payload.title}」が追加されました。`
      } catch (error) {
        console.error(error)
      }
    },

    async send() {
      // バリデーション
      try {
        await this.$refs.form.validate()
      } catch (error) {
        console.error(error)
        await this.$alert('入力に誤りがあります。', 'エラー', {
          type: 'warning',
        })
        return
      }
      // 送信
      try {
        const res = await pushNotificationService.pushForAdmin(
          this.informationId,
          this.form.title,
          this.form.body
        )
        if (!res.ok) throw new Error('cannot push notification.')
      } catch (error) {
        console.error(error)
        await this.$alert('送信に失敗しました。', 'エラー', {
          type: 'warning',
        })
        return
      }
      this.close(true)
    },

    close(success) {
      if (success) this.$emit('ok')
      this.$emit('update:visible', false)
    },
  },
}
</script>
