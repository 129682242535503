// お知らせの作成の変更のためのダイアログ

<template lang="pug">
el-dialog.information-modify-dialog(
  title='お知らせの作成と編集',
  width='80%',
  :close-on-click-modal='false',
  :visible='visible',
  @update:visible='$emit("update:visible", $event)',
  @open='open'
)
  article

    el-form(:model='form', :rules='rules', ref='form')

      el-form-item(label='対象者')
        el-select(
          placeholder='対象グループを選択',
          v-model='form.userGroupId'
        )
          el-option(
            v-for='item in targetUserGroups',
            :key='item.id',
            :label='item.name',
            :value='item.id'
          )
      
      //- 施設類型で絞り込み。対象者は canAttachFiles と同じなので流用
      el-form-item(v-show='canAttachFiles' label='対象の施設類型')
        el-select(
          v-model='form.facilityTypeId'
        )
          el-option(label='すべて', :value='undefined')
          el-option(
            v-for='item in facilityTypes',
            :key='item.id',
            :label='item.description',
            :value='item.id'
          )

      el-form-item(label='表示する日付', prop='date')
        el-date-picker(
          type='date',
          v-model='form.date'
        )

      el-form-item(label='公開', prop='isOpened')
        el-switch(
          active-text='する',
          inactive-text='しない',
          v-model='form.isOpened'
        )

      el-form-item(label='タイトル', prop='title')
        el-input(
          v-model='form.title'
        )

      el-form-item(label='本文', prop='body')
        el-input(
          type='textarea',
          :rows='8',
          v-model='form.body'
        )

      .attachments(v-if='canAttachFiles')
        ul
          li(v-for='file in form.attachments')
            a(:href='file.url', target='_blank', rel='noopener') {{ file.name }}
            a(@click='removeFile(file.timestamp)')
              i.el-icon-error
        file-upload.el-button.el-button--text(
          ref='upload',
          :custom-action='upload',
          v-model='files',
          @input-file='inputFile'
        )
          i.el-icon-circle-plus
          | &nbsp;添付ファイルを追加

    footer
      el-button(
        type='primary',
        @click='save'
      ) {{ this.form.isOpened ? '投稿' : '下書き保存' }}
      el-button(@click='close(false)') キャンセル

</template>

<script>
import FileUpload from 'vue-upload-component'
import getOrganizationFacilityTypes from '@/api/get-organization-facility-types'
import getFileUploadUrl from '@/api/admin/get-file-upload-url'
// TODO: uploadFileの配置パスを変更すべき
import uploadFile from '@/api/admin/upload-file'
import informationService from '@/services/information'

export default {
  name: 'ModifyInformationDialog',

  components: {
    FileUpload,
  },

  data() {
    return {
      form: {
        userGroupId: null,
        date: null,
        isOpened: false,
        title: '',
        body: '',
        attachments: [],
        // 対象となる施設類型。org専用
        facilityTypeId: undefined,
      },
      rules: {
        date: [{ required: true, message: '日付を選択してください。' }],
        title: [{ required: true, message: 'タイトルを入力してください。' }],
        body: [{ required: true, message: '本文を入力してください。' }],
      },
      // アップロード前のファイル
      files: [],
      // 対象のユーザグループ
      targetUserGroups: [],
      // 施設類型
      facilityTypes: [],
    }
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    informationId: {
      type: Number,
    },
  },

  computed: {
    // 添付ファイルの設定が可能か
    canAttachFiles() {
      if (!this.form.userGroupId) return false
      const currentUserGroups = this.targetUserGroups.find(
        (item) => item.id === this.form.userGroupId
      )
      if (!currentUserGroups) return false
      // orgを対象にしたグループなら添付可能
      return currentUserGroups.acceptableRole === 'org'
    },
  },

  // お知らせの対象として設定可能なユーザグループを取得
  async created() {
    try {
      const res = await informationService.getTargetUserGroups()
      if (!res.ok) throw new Error('cannot get target user groups.')
      this.targetUserGroups = res.payload.items
      const facilityTypesResponse = await getOrganizationFacilityTypes()
      if (!facilityTypesResponse.ok) throw new Error('cannot get facility types.')
      this.facilityTypes = facilityTypesResponse.payload.items
    } catch (error) {
      console.error(error)
    }
  },

  methods: {
    open() {
      if (this.$refs.form) this.$refs.form.resetFields()
      if (this.informationId) this._initForModify()
      else this._initForCreate()
    },
    close(success) {
      if (success) this.$emit('update-information')
      this.$emit('update:visible', false)
    },
    // 編集のための準備
    async _initForModify() {
      const response = await informationService.getForAdmin(this.informationId)
      if (!response.ok) return
      const detail = response.payload
      this.form.date = detail.date ? new Date(detail.date.replace(/-/g, '/')) : null
      this.form.isOpened = detail.isOpened
      this.form.title = detail.title
      this.form.body = detail.body
      this.form.attachments = detail.attachments || []
      this.form.userGroupId = detail.userGroupId
      this.form.facilityTypeId = detail.organizationFacilityTypeId || undefined
    },
    _initForCreate() {
      this.form.date = null
      this.form.isOpened = false
      this.form.title = ''
      this.form.body = ''
      this.form.attachments = []
      this.form.userGroupId = this.targetUserGroups[0].id
      this.form.facilityTypeId = undefined
    },
    async save() {
      // バリデーション
      try {
        await this.$refs.form.validate()
      } catch (e) {
        await this.$alert('入力に誤りがあります。', 'エラー', {
          type: 'warning',
        })
        return
      }
      let response
      // orgでなければ、施設類型情報をundefinedに
      const form = { ...this.form }
      if (!this.canAttachFiles) {
        form.facilityTypeId = undefined
      }
      if (this.informationId) {
        response = await informationService.update(this.informationId, form)
      } else {
        response = await informationService.create(form)
      }
      if (!response.ok) {
        this.$alert('お知らせの保存に失敗しました。', 'エラー', {
          type: 'error',
        })
        return
      }
      this.close(true)
    },
    inputFile() {
      if (this.$refs.upload) {
        if (!this.$refs.upload.active) this.$refs.upload.active = true
      }
    },
    async upload(file) {
      const response = await getFileUploadUrl('information', file.name)
      if (!response.ok) return
      const { getUrl, putUrl, id } = response.payload
      const response2 = await uploadFile(putUrl, file.file)
      if (!response2.ok) return
      this.form.attachments.push({
        name: file.name,
        timestamp: id,
        url: getUrl,
      })
    },
    async removeFile(timestamp) {
      try {
        await this.$confirm('本当に削除してもよろしいですか？', '確認', {
          confirmButtonText: '削除',
          cancelButtonText: 'キャンセル',
        })
        this.form.attachments = this.form.attachments.filter((file) => file.timestamp !== timestamp)
      } catch (e) {
        // 何もしない
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.information-modify-dialog

  .attachments
    ul
      list-style-type: none
      li
        display: inline-block
        &:nth-child(n+2)
          margin-left: 1.5rem
        a
          cursor: pointer
          &:last-child
            color: #940b29
            margin-left: 0.3rem

  footer
    margin-top: 1rem
</style>
